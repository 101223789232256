
import { deleteApi, downLoadApis, page, sync } from '@/api/system/apis'
import { list } from '@/api/system/serviceModule'
import JtPagination from '@/components/JtPagination/index.vue'
import { Component,Vue } from 'vue-property-decorator'
  

@Component({
    name: 'Apis',
    components: {
      JtPagination
    }
})
export default class extends Vue {
  serviceModules:Array<string> = []
  tableKey :any
  listLoading = true
  total = 0
  downloading = false
  list = []
  listQuery = {
    current: 1,
    size: 10,
    active: null,
    keyword: null,
    serviceName: null
  }

  mounted() {
      this.getModuleslist()
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载模块信息
  getModuleslist(){
    list().then(res =>{
      const { data } = res
      this.serviceModules = data.map((item:any)=>item.serviceId)
    }).catch()
  }
  //加载api数据
  getList(){
    this.listLoading = true
    page(this.listQuery).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      })
  }

  //同步api数据
  handleApiSyn(){
    this.listLoading = true
    sync()
    this.handleFilter()
  }
  //下载api接口文件
  downloadApis(){
    this.downloading = true
    downLoadApis(this.listQuery)
    setTimeout(() => {
      this.downloading = false
    }, 2 * 1000)
  }

  /**
   * 删除接口
   */
  deleteApi(id:number){
    this.$confirm('确定删除当前接口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteApi({id}).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
          this.getList()
        }).catch(()=>{})
      }).catch(() => {});
  }
}
